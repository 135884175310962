import React, { ReactNode } from 'react';
import { Document, BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ComponentCardCollection } from './ComponentCardCollection';
import ComponentCard from './ComponentCard';

interface ContentProps {
    value: Document;
    heading: string;
    isRelatedContent?:boolean;
    path?:string;
}
interface ContentProp {
    data: object | any;
}

export const FreeFormContent: React.FC<ContentProps> = ({value, heading, isRelatedContent, path}: ContentProps) => {

    let formComponentCardSectionData = (cardData: any) =>{
      let resData: { heading: any; cardDetailedDescription: any }[] = []
      cardData.forEach((item: any) => {
        let cardObj = {
            heading: item?.fields?.heading,
            cardDetailedDescription: item?.fields?.cardDetailedDescription,
        };
        resData.push(cardObj);
    });

    return resData; 
};

    let tableProps = {
        style: { borderWidth: '1px',fontSize: "1.125rem" },
        className: `${(window.location.href?.includes('terms-and-conditions'))?'border-none':''} border-collapse border-black text-left`,
      };
    
      let relatedTableProps = {
        style: { borderWidth: '1px' },
        className: 'text-relatedOrange border-none border-collapse text-left',
      };
    
      let relatedTablePropsCell = {
        style: { borderWidth: '1px' },
        className: ' text-darkText border-none border-collapse border-black text-left',
      }
      let aboutBillClass = 'border-separate border-spacing-1 border border-black text-left'

      const tableBodyStyle = {
        style: { ...tableProps.style },
        className: `${(isRelatedContent || (path?.includes('terms-and-conditions')))?relatedTablePropsCell.className :
          ((path?.includes('bill'))? aboutBillClass:tableProps.className)} ${(path?.includes('bill'))?'':'py-2 mt-[30px]'} w-full`,
      };
    let paraStyle = "text-[1.1rem]"
  const renderOptions: Options = {
    renderText: (text: string) =>
      text
        .split('\n')
        .reduce(
          (children: any, textSegment: any, index: number) => [
            ...children,
            index > 0 && <br key={index} />,
            textSegment,
          ],
          []
        ),

    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => <b>{text}</b>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (_, children: ReactNode) => (
        <h1 className="text-darkText font-semibold text-[2.6rem]">{children}</h1>
      ),
      [BLOCKS.HEADING_3]: (_, children: ReactNode) => (
        <h3 className="text-[1.2rem] text-darkText font-semibold mb-2 mt-4">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (_, children: ReactNode) => (
        <h4 className="py-2 text-darkText mx-auto text-xl mt-5  font-bold">{children}</h4>
      ),
      [BLOCKS.PARAGRAPH]: (_, children: ReactNode) => <p  className={!isRelatedContent?`text-darkText text-[1.125rem]`:paraStyle} style={{color: '#00013A', fontFamily: 'MarkPro-Book SKY', listStyleType: 'disc'}}>{children}</p>,
      [INLINES.HYPERLINK]: (node: any, children: ReactNode) => (
        <a href={node?.data?.uri} className="sky-text-daylight">
          {children}
        </a>
      ),
      [BLOCKS.OL_LIST]: (_, children: ReactNode) => (
          <div className="desk:px-6 pb-8 lg:px-0 mx-auto">
            <ol className="text-lg text-black orderedList desk:pl-10">{children}</ol>
           </div>
      ),
      [BLOCKS.LIST_ITEM]: (_, children: ReactNode) => <li style={{fontFamily: 'MarkPro-Book SKY', fontSize: '20px', lineHeight: '1'}}>{children}</li>,
      [BLOCKS.UL_LIST]: (_, children: ReactNode) => <ul className="text-lg unorderedList ul" style={{listStyleType: 'disc'}}>{children}</ul>,
      [BLOCKS.TABLE]: (_, children: ReactNode) => (
        <table {...tableBodyStyle} >
          <tbody>{children}</tbody>
        </table>
      ),
      [BLOCKS.TABLE_HEADER_CELL]: (_, children: ReactNode) => <th {...(isRelatedContent?relatedTableProps:tableProps)}  >{children}</th>,
      [BLOCKS.TABLE_ROW]: (_, children: ReactNode) => <tr {...(isRelatedContent?relatedTablePropsCell:tableProps)}>{children}</tr>,
      [BLOCKS.TABLE_CELL]: (_, children: ReactNode) => <td {...(isRelatedContent?relatedTablePropsCell:tableProps)}>{children}</td>,
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        if (node.data.target.sys.contentType.sys.id === "freeformContent") {
          return ( 
            <div className='items-center justify-center ml-40'>          
              <FreeFormContent value={node?.data?.target?.fields?.content} heading={node?.data?.target?.fields?.heading} />
              </div>
          );
        }
        else if (node.data.target.sys.contentType.sys.id === "componentCardSection" && node?.data?.target?.fields?.componentCardDisplayTile === 'Four tile' || node?.data?.target?.fields?.componentCardDisplayTile === 'Two tile') {
          let cardDetails = formComponentCardSectionData(node?.data?.target?.fields?.componentCards[0]?.fields?.componentCards)
          return (
          <div>
          <ComponentCardCollection componentCardSectionTitle={''} componentCards={cardDetails as any} componentCardDisplayTile={node?.data?.target?.fields?.componentCardDisplayTile} subTitle={''}/>
          </div>
          )
        }
      },
    },
  };

  return (
    <>
    <div className='mx-[3rem] md:mx-[5rem] lg:mx-[5rem]'><div className='sm:py-5 sky-h2 md:sky-h2-bold sky-text-midnight text-center '><h1 className="text-left sky-h4 pb-0" style={{fontFamily: 'MarkPro-Bold SKY', color: '#00013A'}}>{heading}</h1></div>
    {
      
        documentToReactComponents(value, renderOptions)
        }
        </div>
        </> 

  )
}
