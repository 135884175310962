    import * as React from 'react';
    import ReactPlayerLoader from '@brightcove/react-player-loader';
    import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
    import { HORIZONTAL_VIDEO_PLAYER_URL } from '../../config';

    interface TestimonialPanelProps {
        data?: any | null;
    }

    const TestimonialPanel: React.FC<TestimonialPanelProps> = ({ data }) => {

        const baseURL = HORIZONTAL_VIDEO_PLAYER_URL.split('/');
        const accountId = baseURL[3];

        return (
            <section className="video-testimonials ">
                <div className="max-w-[1280px] mx-auto w-full ">
                    {data.brightCoveId ?
                        <ReactPlayerLoader accountId={accountId} videoId={data.brightCoveId} options={{ autoplay: true, loop: true, playsinline: true, controls:false, muted:true }}/>
                    : 
                    <></>
                    }
                    {/* <div className="bg-[#ebebeb] rounded-[25px] px-16 py-8 lg:px-[110px] lg:py-[80px] my-24 mx-4 md:mx-8 lg:mx-12">
                    <article className="text-[20px] md:text-[24px] leading-[28px] font-skyBold text-left">
                        {documentToReactComponents(data.testimonial)}
                    </article>
                    </div> */}
                </div>
                    {data.buttonLink ? 
                    <div className="w-full flex justify-center my-16">
                        <div>
                            <a href={data.buttonLink} className="text-[12px] lg:text-[16px]  text-white font-skyBold bg-blue-pure rounded-full h-[40px] lg:h-[50px] inline-flex text-center items-center justify-center px-16"><span>{data.buttonLinkText}</span></a>
                            <div className="mt-3 mb-12 underline lg:max-w-[250px] lg:block text-center lg:text-center">{documentToReactComponents(data.termsText)}</div>
                        </div>
                    </div>
                    :<></>}
            </section>
        );
    };

    export default TestimonialPanel;

