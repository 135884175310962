import React from 'react';
import { Accordion } from './Accordion';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { DocumentToReactComponents } from './DocumentToReactComponents';
import { ComponentCardCollection } from './ComponentCardCollection';
import { FreeFormContent } from './FreeformContents';

interface LogoContentAccordion {
  internalName?: string;
  heading?: string;
  subHeading?: string;
  headingBackgroundColor?: string;
  content?: Document;
  logos?: LogoImage[];
}

interface LogoContentAccordionProps {
  logoContentProps: LogoContentAccordion;
}

interface LogoImage {
  url?: string;
  text?: string;
}

interface logoContentProps {
    data: object | any;
}

export const LogoContentAccordion: React.FC<logoContentProps> = ({ data }) => {
  return (
    <>
      <div className="lg:mx-10 md:mx-5 mx-4 pb-5 "  style={{marginTop:"3rem", marginBottom: "3rem"}}>
        <Accordion
          title={data?.heading!}
          bgGradient={data?.headingBackgroundColor}
          active={data?.logos && true}>
          <>
            {data?.logos && (
              <div className="card-body grid lg:grid-cols-12 grid-cols-4">
                {data?.logos?.map((logos: any) => {
                  return (
                    <>
                      <img className="p-2" src={logos.url} alt="channel logo" width="80" height="70" />
                    </>
                  );
                })}
              </div>
            )}
            {data?.content && (
              <div className="px-5 py-4 sky-bg-gray-lighter">
                <FreeFormContent value={data?.content as any} heading={''} />               
              </div>
            )}
            
          </>
        </Accordion>
      </div>
    </>
  );
};
